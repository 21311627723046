//
// Footer
//

.footer {
  background-color: get($footer-config, bg-color);
}

.footer {
  a {
    color: white;
  }
}

.footer {
  a:hover {
    color: $aqs-orange-color-75;
  }
}
