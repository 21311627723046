@mixin getDirection {
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

@mixin getDimensions($widthDesktop,$widthMobile,$heightDesktop,$heightMobile) {
  @include media-breakpoint-up(md) {
    width: $widthDesktop;
    height: $heightDesktop;
  }
  @include media-breakpoint-down(md) {
    width: $widthMobile;
    height: $heightMobile;
  }
}

@mixin getBackgroundBorder() {
  @include media-breakpoint-up(md) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  @include media-breakpoint-down(md) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

@mixin getLoginBorder() {
  @include media-breakpoint-up(md) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  @include media-breakpoint-down(md) {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}



.auth-container-direction {
  @include getDirection;
}

.auth-container-login {
  @include getDimensions(40%, 100%, 100%, 70%);
  @include getLoginBorder;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: white;
}

.auth-container-picture {
  @include getDimensions(60%, 100%, 100%, 30%);
  @include getBackgroundBorder;
  background-color: #123E78;
  display: flex;
  flex-direction: column;

  div {
    flex-grow: 1;
  }
}